import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldAppearance, MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { Provider } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleDefaultOptions } from "@angular/material/button-toggle";

export function provideFrontendMaterialSettings(): Provider[] {
    return [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipCustomConfig},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions},
        {provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: matButtonToggleDefaultOptions},
        {provide: MAT_DATE_LOCALE, useValue: 'fi-FI'},
        {provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}},
    ];
}

const matTooltipCustomConfig: MatTooltipDefaultOptions = {
    ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),

    // Disable touch gestures. Without this, tooltips prevent scrolling and pinch zooming on mobile platforms
    // (see https://github.com/angular/components/issues/4892).
    touchGestures: 'off',
    disableTooltipInteractivity: true,
};

export const DEFAULT_FORM_FIELD_APPEARANCE: MatFormFieldAppearance = "fill";

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
    appearance: DEFAULT_FORM_FIELD_APPEARANCE,
    floatLabel: "always",
    subscriptSizing: "dynamic",
};

const matButtonToggleDefaultOptions: MatButtonToggleDefaultOptions = {
    hideSingleSelectionIndicator: true
};
